import React from "react"
import { PersistGate } from "redux-persist/integration/react"
import Spinner from "react-bootstrap/Spinner"
import { persistor } from "../../state/store_with_persistor"
import QuestionnaireApp from "../../components/QuestionnaireApp"

const QuestionnairePage = ({ location }) => (
  <PersistGate loading={<Loading />} persistor={persistor}>
    <QuestionnaireApp
      basepath="/questionnaire/"
      type="public"
      url={location.href}
    />
  </PersistGate>
)

const Loading = () => (
  <>
    <Spinner animation="border" role="status" />
    <p>Restoring your answers</p>
  </>
)

export default QuestionnairePage
